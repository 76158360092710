



































import { defineComponent } from "@vue/composition-api";
import { applyActionCode } from "firebase/auth";
import { getAuth } from "@/plugins/firebase";
import { Notification } from "element-ui";

type State = {
  oobCode: string;
  isLoading: boolean;
  error: string | null;
  countdown: number;
}

export default defineComponent({
  name: "VerifyEmailPage",
  data: (): State => ({
    oobCode: "",
    isLoading: true,
    error: null,
    countdown: 3,
  }),
  mounted() {
    this.oobCode = this.$route.query.oobCode as string;
    const auth = getAuth();

    applyActionCode(auth, this.oobCode)
      .then(() => {
        this.countdownTick();
      })
      .catch(() => {
        this.error = "Code is invalid or expired. Please login and request a new verification e-mail.";
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  watch: {
    countdown() {
      if (this.countdown === 0) {
        this.onSuccess();
      }
    },
  },
  methods: {
    countdownTick() {
      if (this.countdown > 0) {
        this.countdown--;
        setTimeout(this.countdownTick, 1000);
      }
    },
    onSuccess() {
      this.$router.replace({ name: "LoginPage" });
      Notification.success({
        title: "Operation successful",
        message: "E-mail verified successfully.",
      });
    },
  },
});
